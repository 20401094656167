<template>
  <v-tooltip
    v-model="isTooltipVisible"
    :max-width="maxWidth"
    :right="isRight && $vuetify.breakpoint.mdAndUp"
    :left="isLeft && $vuetify.breakpoint.mdAndUp"
    :bottom="isBottom && $vuetify.breakpoint.mdAndUp"
    :top="isTop || $vuetify.breakpoint.smAndDown"
    :content-class="tooltipContentClass"
  >
    <template v-slot:activator="{ attrs,on }">
      <slot
        name="field"
        :events="{ showTooltip, hideTooltip }"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <div>
      <slot name="tooltipContent" />
    </div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: '200px'
    },
    orientation: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      isTooltipVisible: false
    }
  },
  computed: {
    isRight () {
      return this.orientation === 'right'
    },
    isLeft () {
      return this.orientation === 'left'
    },
    isBottom () {
      return this.orientation === 'bottom'
    },
    isTop () {
      return this.orientation === 'top'
    },
    tooltipContentClass () {
      return this.$vuetify.breakpoint.mdAndUp ? `tooltip-${this.orientation}` : 'tooltip-top'
    }
  },
  methods: {
    showTooltip () {
      this.isTooltipVisible = true
    },
    hideTooltip () {
      this.isTooltipVisible = false
    }
  }
}
</script>

<style scoped>
.v-tooltip__content {
  pointer-events: initial;
}

.tooltip-top::before {
  color: rgba(97, 97, 97, 0.9);
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 8px;
  border-style: solid;
  border-bottom: solid 8px transparent;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}

.tooltip-bottom::before{
  color: rgba(97, 97, 97, 0.9);
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  border-width: 8px;
  border-style: solid;
  border-top: solid 8px transparent;
  border-left: solid 8px transparent;
  border-right: solid 8px transparent;
}

.tooltip-right::before{
  color: rgba(97, 97, 97, 0.9);
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -8px;
  border-width: 8px;
  border-style: solid;
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  border-left: solid 8px transparent;
}

.tooltip-left::before{
  color: rgba(97, 97, 97, 0.9);
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -8px;
  border-width: 8px;
  border-style: solid;
  border-top: solid 8px transparent;
  border-bottom: solid 8px transparent;
  border-right: solid 8px transparent;
}
</style>
