<template>
  <v-select
    ref="paymentMethod"
    v-model="internalValue"
    :items="paymentMethodItems"
    :label="$t('labels.payment-method')"
    :rules="[rules.required]"
    required
    outlined
    @click="$emit('click', value)"
  >
    <template v-slot:item="{item}">
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>

        <v-list-item-subtitle
          v-if="item.onlyYearly"
          class="disabled-subtitle newline"
        >
          {{ $t('billwerk.customer-form.payment-method.only-yearly', { method: item.text }) }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar v-if="item.onlyYearly">
        <v-icon color="grey">
          mdi-lock
        </v-icon>
      </v-list-item-avatar>
    </template>
  </v-select>
</template>

<script>
import { required } from '@/lib/validation'
import feature from '@/mixins/feature'

export const PaymentMethods = Object.freeze({
  BLACK_LABEL_INVOICE_PAYMENT: 'BlackLabel:InvoicePayment',
  CREDIT_CARD_REEPAY: 'CreditCard:Reepay',
  APPLE_PAY_REEPAY: 'ApplePay:Reepay',
  DEBIT_REEPAY: 'Debit:Reepay',
  NONE_NONE: 'none:none'
})

export const paymentMethodLabels = {
  [PaymentMethods.BLACK_LABEL_INVOICE_PAYMENT]: 'settings.payment.payment-method.methods.invoice',
  [PaymentMethods.CREDIT_CARD_REEPAY]: 'settings.payment.payment-method.methods.credit-card',
  [PaymentMethods.APPLE_PAY_REEPAY]: 'settings.payment.payment-method.methods.apple-pay',
  [PaymentMethods.DEBIT_REEPAY]: 'settings.payment.payment-method.methods.debit',
  [PaymentMethods.NONE_NONE]: 'settings.payment.payment-method.methods.none'
}

export default {
  components: { },
  mixins: [feature],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: 'none:none'
    },
    isYearly: {
      type: Boolean,
      default: false
    },
    disabledPyamentMethods: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      rules: { required },
      internalValue: this.value
    }
  },

  computed: {
    paymentMethodItems () {
      const { paymentMethodsOnlyYearly = [], paymentMethods = [] } = this.$features.feature(this.featureNames.BILLWERK).config || {}
      return paymentMethods
        .filter((method) => {
          // Show Apple Pay only if supported
          return method !== 'ApplePay:Reepay' || window.ApplePaySession
        })
        .filter((method) => !this.disabledPyamentMethods.includes(method))
        .map((method) => ({
          text: this.$t(paymentMethodLabels[method]),
          value: method,
          disabled: !this.isYearly && paymentMethodsOnlyYearly.includes(method),
          onlyYearly: !this.isYearly && paymentMethodsOnlyYearly.includes(method)
        }))
        .concat(this.internalValue && (!paymentMethods.includes(this.internalValue) || this.disabledPyamentMethods.includes(this.internalValue))
          ? {
            text: this.$t(paymentMethodLabels[this.internalValue]),
            value: this.internalValue,
            disabled: true
          }
          : [])
    }
  },

  watch: {
    value (newValue) {
      this.internalValue = newValue
    },
    internalValue (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
